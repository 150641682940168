import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import {
  ErrorResponse,
  PaginatedRequest,
  PaginatedResponse,
  ValidationErrorResponse,
} from '../../../types/api.types';
import {
  AttemptStatus,
  AuditViewModel,
  DifficultyLevel,
  MultipleChoiceQuestionViewModel,
  QuestionAttemptViewModel,
  SchoolViewModel,
  Status,
  SubjectViewModel,
  TagViewModel,
  Visibility,
} from '../../questions/api/useMultipleChoiceQuestions';

export interface MetricsViewModel {
  averageTime: number;
  averageScore: number;
}

export interface AssessmentPreviewModel<T> {
  id: string;
  name: string;
  questions: T[];
  subject: SubjectViewModel;
  status: Status;
  visibility: Visibility;
  difficultyLevel: DifficultyLevel;
}

export interface AssessmentViewModel<T> extends AssessmentPreviewModel<T> {
  description?: string;
  timeLimit: number;
  purpose: Purpose;
  referenceStandard: ReferenceStandard;
  methodology: Methodology;
  perspective: Perspective;
  setting: Setting;
  tags: TagViewModel[];
  cutOffScore: number;
  imageUrl?: string;
  maintainsQuestionOrder: boolean;
  official: boolean;
  releaseDate: Date;
  auditLog: AuditViewModel[];
  metrics: MetricsViewModel;
}

export interface MultipleChoiceAssessmentViewModel
  extends AssessmentViewModel<MultipleChoiceQuestionViewModel> {
  numberOfChoices: number;
}

export type Purpose = 'formative' | 'summative' | 'diagnostic';

export type ReferenceStandard = 'criterion-referenced' | 'norm-referenced';

export type Methodology = 'qualitative' | 'quantitative' | 'blended';

export type Perspective =
  | 'self-assessment'
  | 'peer-assessment'
  | 'instructor'
  | 'automated';

export type Setting = 'standardized' | 'informal' | 'formal';

export interface CreateMultipleChoiceAssessmentRequest {
  subjectId: string;
  name: string;
  description: string;
  timeLimit: number;
  visibility: Visibility;
  purpose: Purpose;
  referenceStandard: ReferenceStandard;
  methodology: Methodology;
  perspective: Perspective;
  setting: Setting;
  numberOfChoices: number;
  maintainsQuestionOrder?: boolean;
  official?: boolean;
  releaseDate?: Date;
  tagIds?: string[];
  base64Image?: string;
}

export interface CreateMultipleChoiceAssessmentResponse {
  assessmentId: string;
}

export const useCreateMultipleChoiceAssessment = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateMultipleChoiceAssessmentRequest,
  ): Promise<CreateMultipleChoiceAssessmentResponse> {
    const response =
      await instance.post<CreateMultipleChoiceAssessmentResponse>(
        `/v2/subjects/${request.subjectId}/assessments/mc`,
        request,
      );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceAssessmentResponse,
    AxiosError<ValidationErrorResponse>,
    CreateMultipleChoiceAssessmentRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });

      sendMessage('¡Hecho!', 'Simulacro creado', 'success');
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export interface UpdateMultipleChoiceAssessmentRequest {
  id: string;
  subjectId: string;
  name?: string;
  description?: string;
  timeLimit?: number;
  visibility?: Visibility;
  purpose?: Purpose;
  referenceStandard?: ReferenceStandard;
  methodology?: Methodology;
  perspective?: Perspective;
  setting?: Setting;
  maintainsQuestionOrder?: boolean;
  official?: boolean;
  releaseDate?: Date;
  tagIds?: string[];
  base64Image?: string;
  status?: Status;
}

export const useUpdateMultipleChoiceAssessment = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: UpdateMultipleChoiceAssessmentRequest) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.id}`,
      request,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateMultipleChoiceAssessmentRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessment'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });

      sendMessage('¡Hecho!', 'Simulacro actualizado', 'success');
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export type GetMultipleChoiceAssessmentsRequest = PaginatedRequest & {
  subjectId: string;
  params?: {
    text?: string;
    statuses?: Status[];
    visibilities?: Visibility[];
    purposes?: Purpose[];
    referenceStandards?: ReferenceStandard[];
    methodologies?: Methodology[];
    perspectives?: Perspective[];
    settings?: Setting[];
    difficultyLevels?: DifficultyLevel[];
    tagIds?: string[];
    questionIds?: string[];
    numberOfChoices?: number;
    official?: boolean;
  };
};

export const useGetMultipleChoiceAssessments = (
  request: GetMultipleChoiceAssessmentsRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<
    PaginatedResponse<AssessmentViewModel<string>>
  > {
    const response = await instance.get<
      PaginatedResponse<AssessmentViewModel<string>>
    >(`/v2/subjects/${request.subjectId}/assessments/mc`, {
      params: {
        fromTime: request.fromTime,
        toTime: request.toTime,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        timeSort: request.timeSort,
        order: request.order,
        text: request.params?.text,
        status: request.params?.statuses,
        visibility: request.params?.visibilities,
        purpose: request.params?.purposes,
        referenceStandard: request.params?.referenceStandards,
        methodology: request.params?.methodologies,
        perspective: request.params?.perspectives,
        setting: request.params?.settings,
        difficultyLevel: request.params?.difficultyLevels,
        tagId: request.params?.tagIds,
        questionId: request.params?.questionIds,
        numberOfChoices: request.params?.numberOfChoices,
        official: request.params?.official,
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceAssessments', request],
    queryFn,
    enabled,
  });
};

export type GetMultipleChoiceAssessmentRequest = {
  subjectId: string;
  assessmentId: string;
};

export const useGetMultipleChoiceAssessment = (
  request: GetMultipleChoiceAssessmentRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<MultipleChoiceAssessmentViewModel> {
    const response = await instance.get<MultipleChoiceAssessmentViewModel>(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: [
      'getMultipleChoiceAssessment',
      request.subjectId,
      request.assessmentId,
    ],
    queryFn,
    enabled,
  });
};

export type GetMultipleChoiceAssessmentMetricsRequest = {
  subjectId: string;
  assessmentId: string;
};

export type AssessmentMetricsViewModel = {
  averageTime: number;
  averageScore: number;
  total: number;
  position: number;
  scores: ScoreViewModel[];
};

export type ScoreViewModel = {
  grade: number;
  elapsedTime: number;
  submittedAt: Date;
  username?: string;
  school?: SchoolViewModel;
};

export const useGetMultipleChoiceAssessmentMetrics = (
  request: GetMultipleChoiceAssessmentMetricsRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<AssessmentMetricsViewModel> {
    const response = await instance.get<AssessmentMetricsViewModel>(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}/metrics`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceAssessmentMetrics', request],
    queryFn,
    enabled,
  });
};

export type DeleteMultipleChoiceAssessmentRequest = {
  subjectId: string;
  assessmentId: string;
};

export const useDeleteMultipleChoiceAssessment = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: DeleteMultipleChoiceAssessmentRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    DeleteMultipleChoiceAssessmentRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessment'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });

      sendMessage('¡Hecho!', 'Simulacro eliminado', 'success');
    },
  });
};

export interface AddQuestionToAssessmentRequest {
  subjectId: string;
  assessmentId: string;
  questionId: string;
}

export const useAddQuestionToMultipleChoiceAssessment = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: AddQuestionToAssessmentRequest) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}/questions`,
      {
        questionId: request.questionId,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    AddQuestionToAssessmentRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessment'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export interface RemoveQuestionFromAssessmentRequest {
  subjectId: string;
  assessmentId: string;
  questionId: string;
}

export const useRemoveQuestionFromMultipleChoiceAssessment = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: RemoveQuestionFromAssessmentRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}/questions/${request.questionId}`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    RemoveQuestionFromAssessmentRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessment'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export interface UpdateAssessmentQuestionRequest {
  subjectId: string;
  assessmentId: string;
  questionId: string;
  index: number;
}

export const useUpdateMultipleChoiceAssessmentQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: UpdateAssessmentQuestionRequest) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}/questions/${request.questionId}`,
      {
        index: request.index,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateAssessmentQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessment'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessments'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export type CreateMultipleChoiceAssessmentAttemptCommandResult = {
  id: string;
  name: string;
  description?: string;
  timeLimit: number;
  questionAttempts: {
    questionId: string;
    id: string;
    text: string;
    choices: {
      id: string;
      text: string;
    }[];
    imageUrl?: string;
    bookmarked?: boolean;
  }[];
  imageUrl?: string;
  difficultyLevel: DifficultyLevel;
  subject: SubjectViewModel;
};

export type CreateMultipleChoiceAssessmentAttemptRequest = {
  subjectId: string;
  assessmentId: string;
};

export const useCreateMultipleChoiceAssessmentAttempt = () => {
  const { instance } = useAxios();

  async function mutationFn(
    request: CreateMultipleChoiceAssessmentAttemptRequest,
  ) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/assessments/mc/${request.assessmentId}/attempt`,
    );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceAssessmentAttemptCommandResult,
    AxiosError<ErrorResponse>,
    CreateMultipleChoiceAssessmentAttemptRequest
  >({
    mutationFn,
  });
};

export type CreateRandomMultipleChoiceAssessmentAttemptRequest = {
  subjectId: string;
  numberOfQuestions?: number;
  numberOfChoices?: number;
  unitIds?: string[];
  lessonIds?: string[];
  difficultyLevels?: DifficultyLevel[];
};

export const useCreateRandomMultipleChoiceAssessmentAttempt = () => {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateRandomMultipleChoiceAssessmentAttemptRequest,
  ) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/assessments/mc/random/attempt`,
      {
        numberOfQuestions: request.numberOfQuestions,
        numberOfChoices: request.numberOfChoices,
        unitIds: request.unitIds,
        lessonIds: request.lessonIds,
        difficultyLevels: request.difficultyLevels,
      },
    );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceAssessmentAttemptCommandResult,
    AxiosError<ErrorResponse>,
    CreateRandomMultipleChoiceAssessmentAttemptRequest
  >({
    mutationFn,
    onError: (e: AxiosError<ErrorResponse>) => {
      if (e.response?.data.code === 'not_found') {
        sendMessage(
          '¡Ups!',
          'No se han encontrado preguntas para los temas seleccionados',
          'danger',
        );
      }
    },
  });
};

export type CreateMultipleChoiceAssessmentAttemptFromMissedQuestionsRequest = {
  subjectId: string;
  numberOfQuestions?: number;
};

export const useCreateMultipleChoiceAssessmentAttemptFromMissedQuestions =
  () => {
    const { instance } = useAxios();

    async function mutationFn(
      request: CreateMultipleChoiceAssessmentAttemptFromMissedQuestionsRequest,
    ) {
      const response = await instance.post(
        `/v2/subjects/${request.subjectId}/assessments/mc/missed/attempt`,
        {
          numberOfQuestions: request.numberOfQuestions,
        },
      );

      return response.data;
    }

    return useMutation<
      CreateMultipleChoiceAssessmentAttemptCommandResult,
      AxiosError<ErrorResponse>,
      CreateMultipleChoiceAssessmentAttemptFromMissedQuestionsRequest
    >({
      mutationFn,
    });
  };

export type CreateMultipleChoiceAssessmentAttemptFromBookmarkedQuestionsRequest =
  {
    subjectId: string;
    numberOfQuestions?: number;
  };

export const useCreateMultipleChoiceAssessmentAttemptFromBookmarkedQuestions =
  () => {
    const { instance } = useAxios();

    async function mutationFn(
      request: CreateMultipleChoiceAssessmentAttemptFromBookmarkedQuestionsRequest,
    ) {
      const response = await instance.post(
        `/v2/subjects/${request.subjectId}/assessments/mc/bookmarked/attempt`,
        {
          numberOfQuestions: request.numberOfQuestions,
        },
      );

      return response.data;
    }

    return useMutation<
      CreateMultipleChoiceAssessmentAttemptCommandResult,
      AxiosError<ErrorResponse>,
      CreateMultipleChoiceAssessmentAttemptFromBookmarkedQuestionsRequest
    >({
      mutationFn,
    });
  };

export type SubmitQuestionAttemptRequest = {
  id: string;
  answer: string;
};

export type SubmitAssessmentAttemptRequest = {
  subjectId: string;
  assessmentAttemptId: string;
  questionAttempts: SubmitQuestionAttemptRequest[];
};

export type AssessmentAttemptViewModel<T> = {
  assessmentId: string;
  score: number;
  elapsedTime: number;
  questionAttempts: QuestionAttemptViewModel<T>[];
};

export const useSubmitMultipleChoiceAssessmentAttempt = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: SubmitAssessmentAttemptRequest) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/assessments/mc/attempts/${request.assessmentAttemptId}/submit`,
      {
        questionAttempts: request.questionAttempts,
      },
    );

    return response.data;
  }

  return useMutation<
    AssessmentAttemptViewModel<MultipleChoiceQuestionViewModel>,
    AxiosError<ErrorResponse>,
    SubmitAssessmentAttemptRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessmentAttempt'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceAssessmentAttempts'],
      });
    },
  });
};

export type GetMultipleChoiceAssessmentAttemptsRequest = PaginatedRequest & {
  subjectId: string;
  params?: {
    assessmentId?: string;
    statuses?: AttemptStatus[];
  };
};

export type AssessmentAttemptPreviewModel = {
  id: string;
  name: string;
  score: number;
  elapsedTime: number;
  status: string;
  createdAt: Date;
  questionAttemptsSummary: QuestionAttemptsSummaryViewModel;
};

export type QuestionAttemptsSummaryViewModel = {
  correct: number;
  incorrect: number;
  skipped: number;
};

export const useGetMultipleChoiceAssessmentAttempts = (
  request: GetMultipleChoiceAssessmentAttemptsRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<
    PaginatedResponse<AssessmentAttemptPreviewModel>
  > {
    const response = await instance.get<
      PaginatedResponse<AssessmentAttemptPreviewModel>
    >(`/v2/subjects/${request.subjectId}/assessments/mc/attempts`, {
      params: {
        fromTime: request.fromTime,
        toTime: request.toTime,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        timeSort: request.timeSort,
        order: request.order,
        assessmentId: request.params?.assessmentId,
        status: request.params?.statuses,
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceAssessmentAttempts', request],
    queryFn,
    enabled,
  });
};

export type GetMultipleChoiceAssessmentAttemptRequest = {
  subjectId: string;
  assessmentAttemptId: string;
};

export const useGetMultipleChoiceAssessmentAttempt = (
  request: GetMultipleChoiceAssessmentAttemptRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<
    AssessmentAttemptViewModel<MultipleChoiceQuestionViewModel>
  > {
    const response = await instance.get<
      AssessmentAttemptViewModel<MultipleChoiceQuestionViewModel>
    >(
      `/v2/subjects/${request.subjectId}/assessments/mc/attempts/${request.assessmentAttemptId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: [
      'getMultipleChoiceAssessmentAttempt',
      request.subjectId,
      request.assessmentAttemptId,
    ],
    queryFn,
    enabled,
  });
};
