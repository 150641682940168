import { useEffect, useState } from 'react';

import {
  Badge,
  Card,
  IconButton,
  List,
  ListSkeleton,
} from '@sealfye/ui-components';
import { IoDocumentOutline } from 'react-icons/io5';

import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetMultipleChoiceQuestions } from '../../api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionView } from '../multiple-choice-question-view/MultipleChoiceQuestionView';
import { QuestionListFilterForm } from '../question-list-filter/QuestionListFilter';

type ContainerProps = BaseComponentProps & {
  filter: QuestionListFilterForm;
};

function MultipleChoiceQuestionList({
  className,
  filter,
  testId = 'ui-multiple-choice-question-list',
}: ContainerProps) {
  const [current, setCurrent] = useState<string>();
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });

  const { data: response, isLoading } = useGetMultipleChoiceQuestions({
    subjectId: filter.subject.id,
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    fromTime: filter.fromTime,
    toTime: filter.toTime,
    timeSort: filter.timeSort,
    order: filter.order,
    params: {
      unitId: filter.unit?.id,
      lessonId: filter.lesson?.id,
      tagIds: filter.tags?.map((tag) => tag.id),
      text: filter.text,
      statuses: filter.statuses,
      visibilities: filter.visibilities,
      difficultyLevels: filter.difficultyLevels,
    },
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 1 });
  }, [filter]);

  return (
    <>
      <Card
        title="Preguntas"
        caption={
          response?.totalItems ? `Total: ${response?.totalItems}` : undefined
        }
        className={className}
        testId={testId}
      >
        {isLoading || !response ? (
          <ListSkeleton items={pagination.pageSize} />
        ) : (
          <>
            <List
              items={response.items.map((item) => ({
                text:
                  item.text.length > 120
                    ? item.text.substring(0, 120) + '…'
                    : item.text,
                info: item.text,
                tag:
                  item.status === 'draft' ? (
                    <Badge bg="warning">Borrador</Badge>
                  ) : item.status === 'removed' ? (
                    <Badge bg="danger">Eliminada</Badge>
                  ) : undefined,
                icon: (
                  <IconButton
                    size="sm"
                    icon={<IoDocumentOutline />}
                    onClick={() => {
                      setCurrent(item.id);
                    }}
                  />
                ),
              }))}
            />
            <OffsetPagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalRecords={response.totalItems}
              onPageChange={(pageNumber: number) => {
                setPagination({ ...pagination, pageNumber });
              }}
              pageNeighbours={2}
            />
          </>
        )}
      </Card>
      <ResourceDialog open={current !== undefined}>
        {current && (
          <MultipleChoiceQuestionView
            subjectId={filter.subject.id}
            questionId={current}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
    </>
  );
}

export { MultipleChoiceQuestionList };
