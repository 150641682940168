import { useState } from 'react';

import {
  Accordion,
  AccordionSkeleton,
  Badge,
  Card,
  IconButton,
} from '@sealfye/ui-components';
import { IoEaselOutline } from 'react-icons/io5';

import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { useProfile } from '../../../../context/ProfileContext';
import { useAppActions } from '../../../../state/appStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { dateDiffInDays } from '../../../../utils/date';
import { useGetSubject } from '../../../subjects/api/useSubjects';
import { useGetDiagrams } from '../../api/useDiagrams';
import { DiagramView } from '../diagram-view/DiagramView';

import styles from './DiagramTable.module.scss';

const DAYS_TO_SHOW_BADGE = 5;

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function DiagramTable({
  className,
  subjectId,
  testId = 'ui-diagram-table',
}: ContainerProps) {
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const date = new Date();
  const [current, setCurrent] = useState<string>();

  const { data: subjectResponse, isLoading: isSubjectLoading } = useGetSubject({
    subjectId,
  });

  const { data: diagramsResponse, isLoading: isDiagramsLoading } =
    useGetDiagrams({
      subjectId: subjectId,
      pageNumber: 1,
      pageSize: 1000,
      fromTime: new Date('2010-01-01'),
      toTime: new Date(new Date().setHours(23, 59, 59, 999)),
      timeSort: 'createdAt',
      order: 'desc',
    });

  return (
    <>
      <Card className={className} testId={testId}>
        {isSubjectLoading ||
        isDiagramsLoading ||
        !subjectResponse ||
        !diagramsResponse ? (
          <AccordionSkeleton items={25} />
        ) : (
          <>
            {subjectResponse?.units
              // .filter((unit) =>
              //   diagramsResponse.items.some(
              //     (item) => item.unit?.id === unit.id,
              //   ),
              // )
              .map((unit, index) => (
                <div key={unit.id}>
                  <div className={styles['title']}>{unit.name}</div>
                  <Accordion
                    items={unit.lessons
                      .filter((lesson) =>
                        diagramsResponse.items.some(
                          (item) => item.lesson?.id === lesson.id,
                        ),
                      )
                      .map((lesson) => ({
                        label: {
                          value: (
                            '0' +
                            (subjectResponse.units
                              .filter((unit) => unit.index <= index)
                              .flatMap((unit) => unit.lessons).length +
                              lesson.index)
                          ).slice(-2),
                        },
                        text: lesson.name,
                        tag: diagramsResponse.items.some(
                          (schema) =>
                            schema.lesson?.id === lesson.id &&
                            dateDiffInDays(new Date(schema.createdAt), date) <
                              DAYS_TO_SHOW_BADGE,
                        ) ? (
                          <Badge bg="success">Nuevos</Badge>
                        ) : diagramsResponse.items.some(
                            (schema) =>
                              schema.lesson?.id === lesson.id &&
                              dateDiffInDays(new Date(schema.updatedAt), date) <
                                DAYS_TO_SHOW_BADGE,
                          ) ? (
                          <Badge bg="featured">Actualizados</Badge>
                        ) : null,
                        items: diagramsResponse.items
                          .filter((item) => item.lesson?.id === lesson.id)
                          .map((item) => ({
                            text: item.name,
                            tag:
                              item.createdAt &&
                              dateDiffInDays(new Date(item.createdAt), date) <
                                DAYS_TO_SHOW_BADGE ? (
                                <Badge bg="success">Nuevo</Badge>
                              ) : item.updatedAt &&
                                dateDiffInDays(new Date(item.updatedAt), date) <
                                  DAYS_TO_SHOW_BADGE ? (
                                <Badge bg="featured">Actualizado</Badge>
                              ) : null,
                            icon: (
                              <IconButton
                                style={{
                                  opacity:
                                    !profile.subscription?.isActive &&
                                    item.visibility === 'private'
                                      ? 0.3
                                      : 1,
                                }}
                                size="sm"
                                icon={<IoEaselOutline />}
                                onClick={() => {
                                  if (
                                    !profile.subscription?.isActive &&
                                    item.visibility === 'private'
                                  ) {
                                    setShowGetPremiumPopup(true);
                                    return;
                                  }

                                  setCurrent(item.id);
                                }}
                              />
                            ),
                          })),
                      }))}
                  />
                </div>
              ))}
          </>
        )}
      </Card>
      <ResourceDialog
        open={current !== undefined}
        className={styles['image-viewer']}
      >
        {current && (
          <DiagramView
            subjectId={subjectId}
            diagramId={current}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
    </>
  );
}

export { DiagramTable };
