import { Button, ImageViewer, SwitchCheck } from '@sealfye/ui-components';
import { useFormikContext } from 'formik';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { equal } from '../../../../../utils/equality';
import { DiagramFormValues } from '../DiagramEditor';

import styles from './DiagramReviewStep.module.scss';

type ContainerProps = BaseComponentProps & {
  onCancel: () => void;
};

function DiagramReviewStep({ onCancel }: ContainerProps) {
  const { initialValues, values, isSubmitting, handleChange, handleSubmit } =
    useFormikContext<DiagramFormValues>();

  return (
    <>
      <StepperBody className={styles['body']}>
        <ImageViewer
          className={styles['answer']}
          id=""
          heading={`${values.unit?.name} | ${values.lesson?.name}`}
          title={values.name}
          src={values.base64Image}
          // tags={values.tags.map((tag) => tag.name)}
        />
      </StepperBody>
      <StepperFooter className={styles['footer']}>
        <SwitchCheck
          label="Pregunta abierta a usuarios gratuitos"
          checked={values.visibility === 'public'}
          onChange={() => {
            handleChange({
              target: {
                name: 'visibility',
                value: values.visibility === 'public' ? 'private' : 'public',
              },
            });
          }}
        />
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>

          {!values.id && (
            <Button
              type="button"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            >
              Publicar
            </Button>
          )}

          {values.id && (
            <Button
              type="submit"
              variant="primary"
              loading={isSubmitting}
              disabled={equal(values, initialValues) || isSubmitting}
            >
              Publicar cambios
            </Button>
          )}
        </div>
      </StepperFooter>
    </>
  );
}

export { DiagramReviewStep };
