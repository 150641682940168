const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function formatLongDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatDayDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatMonthDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
  });
}

export function formatSortDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatSortDayDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatLongHourDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function formatHourDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function formatTimestamp(timestamp: number) {
  const h = Math.floor(timestamp / 3600);
  const m = Math.floor(timestamp / 60) % 60;
  const s = timestamp % 60;

  return [h, m, s]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}

export function formatFromSeconds(secs: number) {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = Math.floor(secs % 60);

  return [hours, minutes, seconds];
}

export function getMonthName(month: number) {
  switch (month) {
    case 0:
      return 'Enero';
    case 1:
      return 'Febrero';
    case 2:
      return 'Marzo';
    case 3:
      return 'Abril';
    case 4:
      return 'Mayo';
    case 5:
      return 'Junio';
    case 6:
      return 'Julio';
    case 7:
      return 'Agosto';
    case 8:
      return 'Septiembre';
    case 9:
      return 'Octubre';
    case 10:
      return 'Noviembre';
    case 11:
      return 'Diciembre';
    default:
      return '';
  }
}

export function dateDiffInDays(a: Date, b: Date) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateDiffInMinutes(a: Date, b: Date) {
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
  );
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
  );

  return Math.floor((utc2 - utc1) / (1000 * 60));
}

export function dateDiffInSeconds(a: Date, b: Date) {
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
    a.getSeconds(),
  );
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
    b.getSeconds(),
  );

  return Math.floor((utc2 - utc1) / 1000);
}

export function addSeconds(date: Date, seconds: number) {
  date.setTime(date.getTime() + seconds * 1000);
  return date;
}

export function addHours(date: Date, hours: number) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
}

export function subtractHours(date: Date, hours: number) {
  date.setTime(date.getTime() - hours * 60 * 60 * 1000);
  return date;
}

export function getUTCTimezoneOffset(date: Date) {
  const currentTimezone = date.getTimezoneOffset();
  const { hours, minutes } = toHoursAndMinutes(currentTimezone * -1);

  let gmt = 'UTC';
  gmt += currentTimezone === 0 ? ' ' : currentTimezone > 0 ? ' -' : ' +';
  gmt += `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;

  return gmt;
}

export function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}
