import {
  Button,
  DifficultyLevel,
  MultipleChoiceQuestionAnswer,
  SwitchCheck,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { equal } from '../../../../../utils/equality';
import { MultipleChoiceQuestionFormValues } from '../MultipleChoiceQuestionEditor';

import styles from './MultipleChoiceQuestionReviewStep.module.scss';

type ContainerProps = BaseComponentProps & {
  onCancel: () => void;
};

function MultipleChoiceQuestionReviewStep({ onCancel }: ContainerProps) {
  const { initialValues, values, isSubmitting, handleChange, handleSubmit } =
    useFormikContext<MultipleChoiceQuestionFormValues>();

  return (
    <>
      <StepperBody className={styles['body']}>
        <MultipleChoiceQuestionAnswer
          className={styles['answer']}
          question={{
            id: '',
            text: values.text,
            options: values.choices.map((choice) => choice.text),
            subject: `${values.unit?.name} | ${values.lesson?.name}`,
            selectedOptionIndex:
              values.choices.findIndex((choice) => choice.isCorrect) + 1,
            correctOptionIndex:
              values.choices.findIndex((choice) => choice.isCorrect) + 1,
            bookmarked: false,
            reason: values.explanation,
            difficulty:
              values.difficultyLevel === 'easy'
                ? DifficultyLevel.EASY
                : values.difficultyLevel === 'medium'
                  ? DifficultyLevel.MEDIUM
                  : DifficultyLevel.HARD,
            tags: values.tags.map((tag) => tag.name),
            imageUrl: values.base64Image,
          }}
        />
      </StepperBody>
      <StepperFooter className={styles['footer']}>
        <SwitchCheck
          label="Pregunta abierta a usuarios gratuitos"
          checked={values.visibility === 'public'}
          onChange={() => {
            handleChange({
              target: {
                name: 'visibility',
                value: values.visibility === 'public' ? 'private' : 'public',
              },
            });
          }}
        />
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>

          {!values.id && (
            <>
              <Button
                type="submit"
                variant="outline-primary"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Crear borrador
              </Button>

              <Button
                type="button"
                variant="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => {
                  handleChange({
                    target: {
                      name: 'status',
                      value: 'published',
                    },
                  });

                  handleSubmit();
                }}
              >
                Publicar
              </Button>
            </>
          )}

          {values.id && values.status === 'draft' && (
            <Button
              type="submit"
              variant="outline-primary"
              loading={isSubmitting}
              disabled={equal(values, initialValues) || isSubmitting}
            >
              Guardar cambios
            </Button>
          )}

          {values.id && values.status === 'draft' && (
            <Button
              type="button"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => {
                handleChange({
                  target: {
                    name: 'status',
                    value: 'published',
                  },
                });

                handleSubmit();
              }}
            >
              Publicar
            </Button>
          )}

          {values.id && values.status === 'published' && (
            <Button
              type="submit"
              variant="primary"
              loading={isSubmitting}
              disabled={equal(values, initialValues) || isSubmitting}
            >
              Publicar cambios
            </Button>
          )}
        </div>
      </StepperFooter>
    </>
  );
}

export { MultipleChoiceQuestionReviewStep };
