import { useState } from 'react';

import {
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListSkeleton,
  Search,
  Text,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoEnterOutline, IoExitOutline } from 'react-icons/io5';
import { debounce } from 'throttle-debounce';

import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { MultipleChoiceQuestionViewModel } from '../../../questions/api/useMultipleChoiceQuestions';
import {
  useAddQuestionToMultipleChoiceAssessment,
  useGetMultipleChoiceAssessments,
  useRemoveQuestionFromMultipleChoiceAssessment,
} from '../../api/useMultipleChoiceAssessments';

import styles from './MultipleChoiceAssessmentPreviewList.module.scss';

type ContainerProps = BaseComponentProps & {
  question: MultipleChoiceQuestionViewModel;
  open?: boolean;
  onClose: () => void;
};

function MultipleChoiceAssessmentPreviewList({
  className,
  question,
  open,
  onClose,
  testId = 'ui-multiple-choice-assessment-preview-list',
}: ContainerProps) {
  const [searchText, setSearchText] = useState<string>();
  const [dateRange] = useState<[Date, Date]>([
    new Date(new Date().setFullYear(2021, 0, 1)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10 });

  const { data: response, isLoading } = useGetMultipleChoiceAssessments(
    {
      subjectId: question.subject.id,
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      fromTime: dateRange[0],
      toTime: dateRange[1],
      timeSort: 'updatedAt',
      order: 'desc',
      params: {
        text: searchText,
        statuses: ['draft'],
        perspectives: ['instructor'],
        numberOfChoices: question.choices.length,
      },
    },
    open,
  );

  const { mutate: addQuestion } = useAddQuestionToMultipleChoiceAssessment();

  const { mutate: removeQuestion } =
    useRemoveQuestionFromMultipleChoiceAssessment();

  const debounceFunc = debounce(
    1000,
    (text) => {
      setSearchText(text);
    },
    { atBegin: false },
  );

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={classnames(styles['card-popup'], className)}
        data-testid={testId}
      >
        <Search
          onChange={(e) => {
            debounceFunc(e.target.value);
          }}
          onSearch={(text) => setSearchText(text)}
          className={styles['search']}
        />
        {isLoading || !response ? (
          <ListSkeleton items={pagination.pageSize} />
        ) : (
          <div className={styles['content']}>
            <List
              className={styles['list']}
              items={response.items.map((item) => ({
                text:
                  item.name.length > 50
                    ? item.name.substring(0, 50) + '…'
                    : item.name,
                info: item.name,
                icon: (
                  <>
                    <Text
                      variety="annotation"
                      font="monospace"
                      style={{ marginRight: '5px' }}
                    >
                      {item.questions.length} preguntas
                    </Text>
                    {item.questions.some((q) => q === question.id) ? (
                      <IconButton
                        size="sm"
                        icon={<IoExitOutline />}
                        className={classnames(
                          styles['icon'],
                          styles['icon--danger'],
                        )}
                        onClick={() => {
                          removeQuestion({
                            subjectId: question.subject.id,
                            assessmentId: item.id,
                            questionId: question.id,
                          });
                        }}
                      />
                    ) : (
                      <IconButton
                        size="sm"
                        icon={<IoEnterOutline />}
                        onClick={() => {
                          addQuestion({
                            subjectId: question.subject.id,
                            assessmentId: item.id,
                            questionId: question.id,
                          });
                        }}
                      />
                    )}
                  </>
                ),
              }))}
            />
            <OffsetPagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalRecords={response.totalItems}
              onPageChange={(pageNumber: number) => {
                setPagination({ ...pagination, pageNumber });
              }}
              pageNeighbours={2}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export { MultipleChoiceAssessmentPreviewList };
