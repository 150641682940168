import { useEffect, useState } from 'react';

import { Button } from '@sealfye/ui-components';
import { IoDocumentsOutline } from 'react-icons/io5';

import TheoryImg from '../../../assets/images/_12d27bc9-ed84-4789-a3bf-a0bf6d9e940b.png';
import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import {
  CreateMultipleChoiceQuestionAttemptCommandResult,
  CreateRandomMultipleChoiceQuestionAttemptRequest,
  DifficultyLevel,
  useCreateRandomMultipleChoiceQuestionAttempt,
} from '../../../features/questions/api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionAttempt } from '../../../features/questions/components/multiple-choice-question-attempt/MultipleChoiceQuestionAttempt';
import { SubjectSelectableList } from '../../../features/subjects/components/subject-selectable-list/SubjectSelectableList';
import { BaseComponentProps } from '../../../types/base-component.types';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceQuestionAttemptCard({
  className,
  subjectId,
  testId,
}: ContainerProps) {
  const [showLessons, setShowLessons] = useState(false);

  const [showAttempt, setShowAttempt] = useState(false);
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceQuestionAttemptCommandResult>();
  const [attemptFilter, setAttemptFilter] = useState<{
    subjectId: string;
    numberOfChoices?: number;
    unitIds?: string[];
    lessonIds?: string[];
    difficultyLevels?: DifficultyLevel[];
  }>({
    subjectId: subjectId,
  });

  useEffect(() => {
    setAttemptFilter((prev) => ({
      ...prev,
      subjectId: subjectId,
    }));
  }, [subjectId]);

  const { mutate: createRandomMultipleChoiceQuestionAttempt } =
    useCreateRandomMultipleChoiceQuestionAttempt();

  const createAttempt = (
    request: CreateRandomMultipleChoiceQuestionAttemptRequest,
  ) => {
    createRandomMultipleChoiceQuestionAttempt(request, {
      onSuccess: (response) => {
        setAttempt(response);
      },
      onError: () => {
        setAttempt(undefined);
        setShowAttempt(false);
        setShowLessons(true);
      },
    });
  };

  return (
    <>
      <SectionCard
        title="Preguntas de teoría sueltas"
        image={TheoryImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowLessons(true);
            }}
            suffixIcon={<IoDocumentsOutline />}
          >
            Preguntas sueltas
          </Button>
        }
        className={className}
        testId={testId}
      >
        <p>
          Responde preguntas sueltas con explicación al responder. Más de 20.000
          preguntas disponibles sin límite de tiempo.
        </p>
      </SectionCard>
      <SubjectSelectableList
        open={showLessons}
        subjectId={subjectId}
        onSelect={(ids) => {
          setAttemptFilter((prev) => ({
            ...prev,
            lessonIds: ids.map((id) => id.toString()),
          }));

          setShowLessons(false);
          setShowAttempt(true);
          createAttempt({
            ...attemptFilter,
            lessonIds: ids.map((id) => id.toString()),
          });
        }}
        onClose={() => {
          setShowLessons(false);
        }}
      />
      <BackdropDialog
        open={showAttempt}
        onOpenChange={() => setShowAttempt(false)}
      >
        <MultipleChoiceQuestionAttempt
          attempt={attempt}
          onNext={() => {
            setAttempt(undefined);
            createAttempt(attemptFilter);
          }}
          onClose={() => {
            setShowAttempt(false);
          }}
        />
      </BackdropDialog>
    </>
  );
}

export { MultipleChoiceQuestionAttemptCard };
