import { useState } from 'react';

import {
  BottomTabMenuItem,
  BottomTabNavbar,
  BottomTabSubMenu,
} from '@sealfye/ui-components';
import {
  IoAnalyticsOutline,
  IoArrowDownCircleOutline,
  IoBookOutline,
  IoBookmarksOutline,
  IoCalculatorOutline,
  IoChatbubblesOutline,
  IoClipboardOutline,
  IoConstructOutline,
  IoDocumentTextOutline,
  IoDocumentsOutline,
  IoEaselOutline,
  IoFileTrayFullOutline,
  IoFlashOutline,
  IoHomeOutline,
  IoLibraryOutline,
  IoShapesOutline,
  IoShieldCheckmarkOutline,
  IoTrashBinOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { AdminWidthResolution } from '../../../constants/app';
import { useProfile } from '../../../context/ProfileContext';
import { usePwa } from '../../../context/PwaContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Calculator } from '../../shared/calculator/Calculator';

import styles from './TabNavbar.module.scss';

function TabNavbar() {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { isWidthGreaterThan } = useWindowSize();
  const { prompt, standalone, promptToInstall } = usePwa();

  const [showCalculator, setShowCalculator] = useState(false);

  return (
    <>
      <BottomTabNavbar className={styles['navbar']}>
        <BottomTabMenuItem
          icon={<IoHomeOutline />}
          title={'Inicio'}
          onClick={() => {
            navigate('/');
          }}
        />
        <BottomTabSubMenu icon={<IoLibraryOutline />} title={'Teoría'}>
          <BottomTabMenuItem
            icon={<IoBookOutline />}
            onClick={() => {
              navigate('/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/mc');
            }}
          >
            Preguntas y simulacros
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoEaselOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/esquemas',
              );
            }}
          >
            Esquemas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoDocumentTextOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/recursos',
              );
            }}
          >
            Normativa
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoClipboardOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/simulacros/mc/formativos',
              );
            }}
          >
            Simulacros formativos
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoFlashOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/simulacros/mc/competitivos',
              );
            }}
          >
            Simulacros competitivos
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoShieldCheckmarkOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/simulacros/mc/oficiales',
              );
            }}
          >
            Exámenes oficiales
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoBookmarksOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/preguntas/mc/guardadas',
              );
            }}
          >
            Preguntas guardadas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoTrashBinOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/preguntas/mc/falladas',
              );
            }}
          >
            Preguntas falladas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoFileTrayFullOutline />}
            onClick={() => {
              navigate(
                '/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/simulacros/mc/resultados',
              );
            }}
          >
            Histórico de simulacros
          </BottomTabMenuItem>
        </BottomTabSubMenu>
        <BottomTabSubMenu icon={<IoShapesOutline />} title={'Psicotécnicos'}>
          <BottomTabMenuItem
            icon={<IoBookOutline />}
            onClick={() => {
              navigate('/materia/63a0be75-65d1-4576-8925-191a546b3d34/mc');
            }}
          >
            Preguntas y simulacros
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoClipboardOutline />}
            onClick={() => {
              navigate(
                '/materia/63a0be75-65d1-4576-8925-191a546b3d34/simulacros/mc/formativos',
              );
            }}
          >
            Simulacros formativos
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoBookmarksOutline />}
            onClick={() => {
              navigate(
                '/materia/63a0be75-65d1-4576-8925-191a546b3d34/preguntas/mc/guardadas',
              );
            }}
          >
            Preguntas guardadas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoTrashBinOutline />}
            onClick={() => {
              navigate(
                '/materia/63a0be75-65d1-4576-8925-191a546b3d34/preguntas/mc/falladas',
              );
            }}
          >
            Preguntas falladas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoFileTrayFullOutline />}
            onClick={() => {
              navigate(
                '/materia/63a0be75-65d1-4576-8925-191a546b3d34/simulacros/mc/resultados',
              );
            }}
          >
            Histórico de simulacros
          </BottomTabMenuItem>
        </BottomTabSubMenu>
        <BottomTabSubMenu icon={<IoChatbubblesOutline />} title={'Inglés'}>
          <BottomTabMenuItem
            icon={<IoBookOutline />}
            onClick={() => {
              navigate('/materia/fad6092b-5a08-4542-bc9a-f3a118b99041/mc');
            }}
          >
            Preguntas y simulacros
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoBookmarksOutline />}
            onClick={() => {
              navigate(
                '/materia/fad6092b-5a08-4542-bc9a-f3a118b99041/preguntas/mc/guardadas',
              );
            }}
          >
            Preguntas guardadas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoTrashBinOutline />}
            onClick={() => {
              navigate(
                '/materia/fad6092b-5a08-4542-bc9a-f3a118b99041/preguntas/mc/falladas',
              );
            }}
          >
            Preguntas falladas
          </BottomTabMenuItem>
          <BottomTabMenuItem
            icon={<IoFileTrayFullOutline />}
            onClick={() => {
              navigate(
                '/materia/fad6092b-5a08-4542-bc9a-f3a118b99041/simulacros/mc/resultados',
              );
            }}
          >
            Histórico de simulacros
          </BottomTabMenuItem>
        </BottomTabSubMenu>
        <BottomTabMenuItem
          icon={<IoAnalyticsOutline />}
          title={'Progreso'}
          onClick={() => {
            navigate('/progreso');
          }}
        />
        <BottomTabMenuItem
          icon={<IoCalculatorOutline />}
          title="Calculadora"
          onClick={() => {
            setShowCalculator(true);
          }}
        />
        {isWidthGreaterThan(AdminWidthResolution) &&
          profile.role === 'admin' && (
            <BottomTabSubMenu
              icon={<IoConstructOutline />}
              title={'Administración'}
            >
              <BottomTabMenuItem
                icon={<IoEaselOutline />}
                onClick={() => {
                  navigate('/admin/esquemas');
                }}
              >
                Esquemas
              </BottomTabMenuItem>
              <BottomTabMenuItem
                icon={<IoClipboardOutline />}
                onClick={() => {
                  navigate('/admin/simulacros');
                }}
              >
                Simulacros
              </BottomTabMenuItem>
              <BottomTabMenuItem
                icon={<IoDocumentsOutline />}
                onClick={() => {
                  navigate('/admin/preguntas');
                }}
              >
                Preguntas
              </BottomTabMenuItem>
              <BottomTabMenuItem
                icon={<IoWarningOutline />}
                onClick={() => {
                  navigate('/admin/reportes');
                }}
              >
                Reportes
              </BottomTabMenuItem>
            </BottomTabSubMenu>
          )}
        {prompt != null && !standalone && (
          <BottomTabMenuItem
            icon={<IoArrowDownCircleOutline />}
            title="Instalar app"
            onClick={promptToInstall}
            className={styles['install']}
          />
        )}
      </BottomTabNavbar>
      <Calculator
        open={showCalculator}
        onClose={() => setShowCalculator(false)}
      />
    </>
  );
}

export { TabNavbar };
