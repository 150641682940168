import {
  Icon,
  Logo,
  ScrollAwareNavbar,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@sealfye/ui-components';
import { IoFlash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { useProfile } from '../../../context/ProfileContext';
import { UserOptions } from './UserOptions';

import styles from './Navbar.module.scss';

function Navbar() {
  const navigate = useNavigate();
  const { profile } = useProfile();

  return (
    <ScrollAwareNavbar className={styles['navbar']}>
      <div className={styles['navbar__content']}>
        <div className={styles['logo-wrapper']} onClick={() => navigate('/')}>
          <Logo className={styles['logo']} variant="icon" />
        </div>
        <div className={styles['user']}>
          <Tooltip placement="bottom">
            <TooltipTrigger asChild={true}>
              <div className={styles['award']}>
                <Icon className={styles['icon']} icon={<IoFlash />} size="sm" />
                <span className={styles['points']}>{profile.credits ?? 0}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Puntos obtenidos</TooltipContent>
          </Tooltip>
          <UserOptions />
        </div>
      </div>
    </ScrollAwareNavbar>
  );
}

export { Navbar };
