import { PropsWithChildren, useEffect, useState } from 'react';

import {
  DifficultyLevel,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAnswerType,
} from '@sealfye/ui-components';
import {
  FcAcceptDatabase,
  FcAddDatabase,
  FcDataBackup,
  FcDeleteDatabase,
} from 'react-icons/fc';

import { Loading } from '../../../../components/core/Loading';
import { AdminWidthResolution } from '../../../../constants/app';
import { useProfile } from '../../../../context/ProfileContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { MultipleChoiceAssessmentPreviewList } from '../../../assessments/components/multiple-choice-assessment-preview-list/MultipleChoiceAssessmentPreviewList';
import {
  useBookmarkMultipleChoiceQuestion,
  useCreateMultipleChoiceQuestionErrorReport,
  useGetMultipleChoiceQuestion,
  useUnbookmarkMultipleChoiceQuestion,
} from '../../api/useMultipleChoiceQuestions';
import { FeedbackForm } from '../feedback-form/FeedbackForm';
import { MultipleChoiceQuestionEditor } from '../multiple-choice-question-editor/MultipleChoiceQuestionEditor';

import styles from './MultipleChoiceQuestionView.module.scss';

export type ContainerProps = BaseComponentProps & {
  subjectId: string;
  questionId: string;
  selectedChoiceId?: string;
  onClose: () => void;
};

function MultipleChoiceQuestionView({
  children,
  subjectId,
  questionId,
  selectedChoiceId,
  onClose,
}: PropsWithChildren<ContainerProps>) {
  const { profile } = useProfile();
  const { isWidthGreaterThan } = useWindowSize();

  const [question, setQuestion] = useState<MultipleChoiceQuestionAnswerType>();
  const [showEdit, setShowEdit] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);

  const {
    data: response,
    isLoading,
    isError,
  } = useGetMultipleChoiceQuestion({
    subjectId,
    questionId,
  });

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  const { mutateAsync: bookmarkMultipleChoiceQuestion } =
    useBookmarkMultipleChoiceQuestion();

  const { mutateAsync: unbookmarkMultipleChoiceQuestion } =
    useUnbookmarkMultipleChoiceQuestion();

  const { mutate: createMultipleChoiceQuestionErrorReport } =
    useCreateMultipleChoiceQuestionErrorReport();

  useEffect(() => {
    if (response) {
      setQuestion({
        id: response.id,
        text: response.text,
        subject:
          response.lesson?.name ?? response.unit?.name ?? response.subject.name,
        caption:
          response.status === 'removed' ? '(Pregunta eliminada)' : undefined,
        imageUrl: response.imageUrl,
        bookmarked: response.bookmarked,
        tags: response.tags.map((tag) => tag.name),
        difficulty:
          response.difficultyLevel === 'easy'
            ? DifficultyLevel.EASY
            : response.difficultyLevel === 'medium'
              ? DifficultyLevel.MEDIUM
              : DifficultyLevel.HARD,
        options: response.choices.map((choice) => choice.text),
        selectedOptionIndex: selectedChoiceId
          ? response.choices.findIndex(
              (choice) => choice.id === selectedChoiceId,
            ) + 1
          : 0,
        reason: response.explanation,
        correctOptionIndex:
          response.choices.findIndex((choice) => choice.isCorrect) + 1,
        answerRate: {
          correct: response.metrics.correctPercentage,
          skipped: response.metrics.skippedPercentage,
          incorrect: response.metrics.incorrectPercentage,
        },
        auditLog:
          profile.role === 'admin'
            ? response.auditLog.map((log) => ({
                icon:
                  log.action === 'created' ? (
                    <FcAddDatabase />
                  ) : log.action === 'updated' ? (
                    <FcDataBackup />
                  ) : log.action === 'deleted' ? (
                    <FcDeleteDatabase />
                  ) : (
                    <FcAcceptDatabase />
                  ),
                title:
                  log.action === 'created'
                    ? 'Pregunta creada'
                    : log.action === 'updated'
                      ? 'Pregunta actualizada'
                      : log.action === 'deleted'
                        ? 'Pregunta eliminada'
                        : 'Pregunta verificada',

                description: `Por ${log.username}`,
                date: new Date(log.date),
              }))
            : undefined,
        tip:
          response.missesCount > 1 ? (
            <div className={styles['tooltip']}>
              Has fallado esta pregunta {response.missesCount} veces
            </div>
          ) : undefined,
      });
    }
  }, [response]);

  if (isLoading || !question) {
    return <Loading />;
  }

  return (
    <>
      <MultipleChoiceQuestionAnswer
        question={question}
        onClose={onClose}
        onBookmark={
          profile.subscription?.isActive && response?.status !== 'removed'
            ? async (id) => {
                await bookmarkMultipleChoiceQuestion({
                  subjectId,
                  questionId: id,
                });
              }
            : undefined
        }
        onUnbookmark={
          profile.subscription?.isActive
            ? async (id) => {
                await unbookmarkMultipleChoiceQuestion({
                  subjectId,
                  questionId: id,
                });
              }
            : undefined
        }
        onFeedback={
          profile.subscription?.isActive && response?.status !== 'removed'
            ? () => {
                setShowFeedback(true);
              }
            : undefined
        }
        onEdit={
          profile.role === 'admin' &&
          response?.status !== 'removed' &&
          isWidthGreaterThan(AdminWidthResolution)
            ? () => {
                setShowEdit(true);
              }
            : undefined
        }
        onPush={
          profile.role === 'admin' &&
          response?.status !== 'removed' &&
          response?.status !== 'draft' &&
          isWidthGreaterThan(AdminWidthResolution)
            ? () => {
                setShowAssessments(true);
              }
            : undefined
        }
      >
        {children}
      </MultipleChoiceQuestionAnswer>
      {response && (
        <MultipleChoiceQuestionEditor
          open={showEdit}
          question={response}
          onClose={() => setShowEdit(false)}
        />
      )}
      {response && (
        <MultipleChoiceAssessmentPreviewList
          open={showAssessments}
          question={response}
          onClose={() => setShowAssessments(false)}
        />
      )}
      <FeedbackForm
        open={showFeedback}
        title="¿Has visto algún error en esta pregunta?"
        onFeedback={async (feedback) => {
          createMultipleChoiceQuestionErrorReport(
            {
              subjectId,
              questionId,
              message: feedback,
            },
            {
              onSuccess: () => {
                setShowFeedback(false);
              },
            },
          );
        }}
        onClose={() => setShowFeedback(false)}
      />
    </>
  );
}

export { MultipleChoiceQuestionView };
