import { useState } from 'react';

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  IconButton,
  List,
  ListSkeleton,
} from '@sealfye/ui-components';
import { IoDocumentOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { Empty } from '../../../../components/core/Empty';
import { BackdropDialog } from '../../../../components/shared/backdrop/BackdropDialog';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { formatLongHourDate } from '../../../../utils/date';
import {
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useCreateMultipleChoiceAssessmentAttemptFromMissedQuestions,
} from '../../../assessments/api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentAttempt } from '../../../assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';
import {
  MissViewModel,
  useGetMultipleChoiceMissedQuestions,
} from '../../api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionView } from '../multiple-choice-question-view/MultipleChoiceQuestionView';

import styles from './MultipleChoiceMissedQuestionsList.module.scss';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceMissedQuestionsList({
  className,
  subjectId,
  testId = 'ui-multiple-choice-missed-questions-list',
}: ContainerProps) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState<MissViewModel>();
  const { data: response, isLoading } = useGetMultipleChoiceMissedQuestions({
    subjectId: subjectId,
  });

  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();

  const [numberOfItems, setNumberOfItems] = useState(25);

  const { mutate: createMultipleChoiceAssessmentAttempt, isPending } =
    useCreateMultipleChoiceAssessmentAttemptFromMissedQuestions();

  return (
    <>
      {response?.length === 0 ? (
        <Empty title="No tienes preguntas falladas" />
      ) : (
        <>
          <Card
            title="Falladas recientemente"
            className={className}
            testId={testId}
          >
            {isLoading || !response ? (
              <ListSkeleton items={30} />
            ) : (
              <>
                <List
                  items={response.map((item) => ({
                    text:
                      item.question.text.length > 120
                        ? item.question.text.substring(0, 120) + '…'
                        : item.question.text,
                    info: `Fallada el ${formatLongHourDate(item.createdAt)}`,
                    tag:
                      item.question.status === 'draft' ? (
                        <Badge bg="warning">Borrador</Badge>
                      ) : item.question.status === 'removed' ? (
                        <Badge bg="danger">Eliminada</Badge>
                      ) : undefined,
                    icon: (
                      <IconButton
                        size="sm"
                        icon={<IoDocumentOutline />}
                        onClick={() => {
                          setCurrent(item);
                        }}
                      />
                    ),
                  }))}
                />
              </>
            )}
          </Card>
          {!isLoading && response && response?.length > 0 && (
            <div className={styles['attempt']}>
              <ButtonGroup
                disabled={isPending}
                onSelect={(value) => setNumberOfItems(+value)}
                options={[
                  {
                    value: 25,
                    label: '25',
                    selected: numberOfItems === 25,
                  },
                  {
                    value: 50,
                    label: '50',
                    selected: numberOfItems === 50,
                  },
                  {
                    value: 100,
                    label: '100',
                    selected: numberOfItems === 100,
                  },
                ]}
              />
              <Button
                disabled={isPending}
                loading={isPending}
                onClick={() => {
                  createMultipleChoiceAssessmentAttempt(
                    {
                      subjectId: subjectId,
                      numberOfQuestions: numberOfItems,
                    },
                    {
                      onSuccess: (response) => {
                        setAttempt(response);
                      },
                    },
                  );
                }}
              >
                Crear simulacro
              </Button>
            </div>
          )}
        </>
      )}
      <ResourceDialog open={current !== undefined}>
        {current && (
          <MultipleChoiceQuestionView
            subjectId={subjectId}
            questionId={current.question.id}
            selectedChoiceId={current.providedAnswer}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
      <BackdropDialog open={attempt !== undefined}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            setAttempt(undefined);
            navigate(
              `/materia/${subjectId}/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </>
  );
}

export { MultipleChoiceMissedQuestionsList };
