import { useEffect, useState } from 'react';

import {
  ActionDialogButtons,
  ActionDialogContent,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTrigger,
  ErrorsType,
  FormFeedback,
  FormGroup,
  FormLabel,
  FormRow,
  FormSection,
  IconButton,
  ImageUpload,
  Input,
  SwitchCheck,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';
import { IoTrashOutline } from 'react-icons/io5';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import { Editor } from '../../../../../components/shared/editor/Editor';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { SubjectsForm } from '../../../../subjects/components/subjects-form/SubjectsForm';
import { TagsForm } from '../../../../tags/components/tags-form/TagsForm';
import { MultipleChoiceAssessmentFormValues } from '../MultipleChoiceAssessmentEditor';

import styles from './MultipleChoiceAssessmentStep.module.scss';

type ContainerProps = BaseComponentProps & {
  onNext: () => void;
  onCancel: () => void;
  onDelete: () => void;
};

function MultipleChoiceAssessmentStep({
  onNext,
  onCancel,
  onDelete,
}: ContainerProps) {
  const {
    setTouched,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    setFieldTouched,
    setErrors,
  } = useFormikContext<MultipleChoiceAssessmentFormValues>();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (values.id) {
      setTouched(
        Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      );
    }
  }, [values]);

  return (
    <>
      <StepperBody>
        <FormSection title={values.id ? 'Editar simulacro' : 'Nuevo simulacro'}>
          <SubjectsForm unitEnabled={false} lessonEnabled={false} />
          <FormGroup>
            <FormLabel>Nombre</FormLabel>
            <Input
              id="name"
              value={values.name}
              onChange={handleChange}
              error={touched.name && !!errors.name}
            />
            {touched.name && errors.name && (
              <FormFeedback variant="danger">{errors.name}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>Descripción</FormLabel>
            <Editor
              content={values.description}
              onContentChange={(value) => {
                handleChange({
                  target: {
                    name: 'description',
                    value,
                  },
                });
              }}
              error={touched.description && !!errors.description}
            />
            {touched.description && errors.description && (
              <FormFeedback variant="danger">{errors.description}</FormFeedback>
            )}
            <FormFeedback>
              Cada retroalimentación ayuda a entender y memorizar la respuesta,
              siempre que puedas elabora una buena descripción de los
              contenidos.
            </FormFeedback>
          </FormGroup>
          <TagsForm />
          <FormGroup>
            <FormLabel>Imagen asociada</FormLabel>
            <ImageUpload
              dataURL={values.base64Image}
              onChange={(image?: string) => {
                handleChange({
                  target: {
                    name: 'base64Image',
                    value: image ?? '',
                  },
                });
              }}
              onError={(error?: ErrorsType) => {
                setFieldTouched('base64Image', true, false);

                if (error?.maxFileSize) {
                  setErrors({
                    ...errors,
                    base64Image: 'La imagen no puede superar los 5MB',
                  });
                }

                if (error?.maxNumber) {
                  setErrors({
                    ...errors,
                    base64Image: 'No puedes subir más de 1 imagen',
                  });
                }

                if (error?.acceptType) {
                  setErrors({
                    ...errors,
                    base64Image: 'El formato de la imagen no es válido',
                  });
                }

                if (error?.resolution) {
                  setErrors({
                    ...errors,
                    base64Image: 'La resolución de la imagen es muy alta',
                  });
                }
              }}
            />
            {touched.base64Image && errors.base64Image && (
              <FormFeedback variant="danger">{errors.base64Image}</FormFeedback>
            )}
            <FormFeedback>
              La imagen no puede superar los 5MB, y debe estar en un formato
              válido (jpg, jpeg, png).
            </FormFeedback>
          </FormGroup>
        </FormSection>

        <FormSection title="Preguntas">
          <FormRow>
            <FormGroup>
              <FormLabel>Número de opciones</FormLabel>
              <ButtonGroup
                onSelect={handleChange}
                disabled={values.id !== undefined}
                error={touched.numberOfChoices && !!errors.numberOfChoices}
                options={[
                  {
                    value: 3,
                    label: '3',
                    selected: values.numberOfChoices === 3,
                  },
                  {
                    value: 4,
                    label: '4',
                    selected: values.numberOfChoices === 4,
                  },
                  {
                    value: 5,
                    label: '5',
                    selected: values.numberOfChoices === 5,
                  },
                ]}
              />
              {touched.numberOfChoices && errors.numberOfChoices && (
                <FormFeedback variant="danger">
                  {errors.numberOfChoices}
                </FormFeedback>
              )}
              <FormFeedback>
                Selecciona el número de opciones que van a contener las
                preguntas.
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel>Disposición</FormLabel>
              <SwitchCheck
                label="Mantener el orden de las preguntas"
                checked={values.maintainsQuestionOrder}
                onChange={() => {
                  handleChange({
                    target: {
                      name: 'maintainsQuestionOrder',
                      value: !values.maintainsQuestionOrder,
                    },
                  });
                }}
              />
              {touched.maintainsQuestionOrder &&
                errors.maintainsQuestionOrder && (
                  <FormFeedback variant="danger">
                    {errors.maintainsQuestionOrder}
                  </FormFeedback>
                )}
            </FormGroup>
          </FormRow>
        </FormSection>

        <FormSection title="Metadatos">
          <FormRow>
            <FormGroup>
              <FormLabel>Tipo de simulacro</FormLabel>
              <ButtonGroup
                onSelect={(value) => {
                  handleChange({
                    target: {
                      name: 'purpose',
                      value: value === 'formative' ? 'formative' : 'summative',
                    },
                  });
                  handleChange({
                    target: {
                      name: 'referenceStandard',
                      value:
                        value === 'formative'
                          ? 'criterion-referenced'
                          : 'norm-referenced',
                    },
                  });
                  handleChange({
                    target: {
                      name: 'methodology',
                      value: 'quantitative',
                    },
                  });
                  handleChange({
                    target: {
                      name: 'perspective',
                      value: 'instructor',
                    },
                  });
                  handleChange({
                    target: {
                      name: 'setting',
                      value: value === 'formative' ? 'formal' : 'standardized',
                    },
                  });
                  handleChange({
                    target: {
                      name: 'official',
                      value: value === 'official',
                    },
                  });
                }}
                error={touched.setting && !!errors.setting}
                options={[
                  {
                    value: 'formative',
                    label: 'Formativo',
                    selected: values.setting === 'formal',
                  },
                  {
                    value: 'competitive',
                    label: 'Simulacro competitivo',
                    selected:
                      values.setting === 'standardized' && !values.official,
                  },
                  {
                    value: 'official',
                    label: 'Examen oficial',
                    selected:
                      values.setting === 'standardized' && values.official,
                  },
                ]}
              />
              {touched.setting && errors.setting && (
                <FormFeedback variant="danger">{errors.setting}</FormFeedback>
              )}
              <FormFeedback>Selecciona el tipo de simulacro.</FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel>Tiempo límite</FormLabel>
              <Input
                id="timeLimit"
                type="number"
                value={values.timeLimit / 60}
                min={1}
                max={50}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'timeLimit',
                      value: +e.target.value * 60,
                    },
                  });
                }}
                error={touched.timeLimit && !!errors.timeLimit}
              />
              {touched.timeLimit && errors.timeLimit && (
                <FormFeedback variant="danger">{errors.timeLimit}</FormFeedback>
              )}
              <FormFeedback>
                El tiempo límite en minutos para completar el simulacro.
              </FormFeedback>
            </FormGroup>
          </FormRow>
        </FormSection>
      </StepperBody>

      <StepperFooter className={styles['footer']}>
        <div className={styles['footer__left']}>
          {values.id && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild={true}>
                <IconButton
                  icon={<IoTrashOutline />}
                  className={styles['trash']}
                  onClick={() => setOpen((v) => !v)}
                  disabled={isSubmitting}
                />
              </DialogTrigger>
              <DialogContent>
                <ActionDialogContent title="¿Estás segurx de que deseas eliminar este simulacro?">
                  <ActionDialogButtons>
                    <Button
                      variant="outline-primary"
                      onClick={() => setOpen((v) => !v)}
                    >
                      Cancelar
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                      Eliminar
                    </Button>
                  </ActionDialogButtons>
                </ActionDialogContent>
              </DialogContent>
            </Dialog>
          )}
        </div>
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              setTouched(
                Object.keys(values).reduce(
                  (acc, key) => ({ ...acc, [key]: true }),
                  {},
                ),
              );

              if (Object.keys(errors).length === 0) {
                onNext();
              }
            }}
          >
            Siguiente
          </Button>
        </div>
      </StepperFooter>
    </>
  );
}

export { MultipleChoiceAssessmentStep };
