import { useEffect, useState } from 'react';

import {
  DifficultyLevel,
  MultipleChoiceQuestion,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAnswerType,
  MultipleChoiceQuestionType,
} from '@sealfye/ui-components';
import {
  FcAcceptDatabase,
  FcAddDatabase,
  FcDataBackup,
  FcDeleteDatabase,
} from 'react-icons/fc';

import { Loading } from '../../../../components/core/Loading';
import { useProfile } from '../../../../context/ProfileContext';
import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  CreateMultipleChoiceQuestionAttemptCommandResult,
  MultipleChoiceQuestionViewModel,
  SubmitQuestionAttemptCommandResult,
  useBookmarkMultipleChoiceQuestion,
  useSubmitMultipleChoiceQuestionAttempt,
  useUnbookmarkMultipleChoiceQuestion,
} from '../../api/useMultipleChoiceQuestions';
import { Countdown } from './Countdown';

import styles from './MultipleChoiceQuestionChallengeAttempt.module.scss';

export type ContainerProps = BaseComponentProps & {
  attempt?: CreateMultipleChoiceQuestionAttemptCommandResult;
  onNext: () => void;
};

function MultipleChoiceQuestionChallengeAttempt({
  attempt,
  onNext,
}: ContainerProps) {
  const { profile } = useProfile();

  const [attemptQuestion, setAttemptQuestion] =
    useState<MultipleChoiceQuestionType>();
  const [attemptResultQuestion, setAttemptResultQuestion] =
    useState<MultipleChoiceQuestionAnswerType>();
  const [attemptResult, setAttemptResult] =
    useState<
      SubmitQuestionAttemptCommandResult<MultipleChoiceQuestionViewModel>
    >();

  const { mutate: submitMultipleChoiceQuestionAttempt, isPending } =
    useSubmitMultipleChoiceQuestionAttempt();

  const { mutateAsync: bookmarkMultipleChoiceQuestion } =
    useBookmarkMultipleChoiceQuestion();

  const { mutateAsync: unbookmarkMultipleChoiceQuestion } =
    useUnbookmarkMultipleChoiceQuestion();

  useEffect(() => {
    if (attempt) {
      setAttemptQuestion({
        id: attempt.id,
        text: attempt.text,
        subject: attempt.lesson?.name,
        options: attempt.choices.map((choice) => choice.text),
        selectedOptionIndex: 0,
        bookmarked: attempt.bookmarked ?? false,
      });
    }
  }, [attempt]);

  useEffect(() => {
    if (attemptResult) {
      setAttemptResultQuestion({
        id: attemptResult.questionAttempt.question.id,
        text: attemptResult.questionAttempt.question.text,
        subject:
          attemptResult.questionAttempt.question.lesson?.name ??
          attemptResult.questionAttempt.question.unit?.name ??
          attemptResult.questionAttempt.question.subject.name,
        caption:
          attemptResult.questionAttempt.question.status === 'removed'
            ? '(Pregunta eliminada)'
            : undefined,
        imageUrl: attemptResult.questionAttempt.question.imageUrl,
        bookmarked: attemptResult.questionAttempt.question.bookmarked,
        tags: attemptResult.questionAttempt.question.tags.map(
          (tag) => tag.name,
        ),
        difficulty:
          attemptResult.questionAttempt.question.difficultyLevel === 'easy'
            ? DifficultyLevel.EASY
            : attemptResult.questionAttempt.question.difficultyLevel ===
                'medium'
              ? DifficultyLevel.MEDIUM
              : DifficultyLevel.HARD,
        options: attemptResult.questionAttempt.question.choices.map(
          (choice) => choice.text,
        ),
        selectedOptionIndex: attemptResult.questionAttempt.answer
          ? attemptResult.questionAttempt.question.choices.findIndex(
              (choice) => choice.id === attemptResult.questionAttempt.answer,
            ) + 1
          : 0,
        reason: attemptResult.questionAttempt.question.explanation,
        correctOptionIndex:
          attemptResult.questionAttempt.question.choices.findIndex(
            (choice) => choice.isCorrect,
          ) + 1,
        answerRate: {
          correct:
            attemptResult.questionAttempt.question.metrics.correctPercentage,
          skipped:
            attemptResult.questionAttempt.question.metrics.skippedPercentage,
          incorrect:
            attemptResult.questionAttempt.question.metrics.incorrectPercentage,
        },
        auditLog:
          profile.role === 'admin'
            ? attemptResult.questionAttempt.question.auditLog.map((log) => ({
                icon:
                  log.action === 'created' ? (
                    <FcAddDatabase />
                  ) : log.action === 'updated' ? (
                    <FcDataBackup />
                  ) : log.action === 'deleted' ? (
                    <FcDeleteDatabase />
                  ) : (
                    <FcAcceptDatabase />
                  ),
                title:
                  log.action === 'created'
                    ? 'Pregunta creada'
                    : log.action === 'updated'
                      ? 'Pregunta actualizada'
                      : log.action === 'deleted'
                        ? 'Pregunta eliminada'
                        : 'Pregunta verificada',

                description: `Por ${log.username}`,
                date: new Date(log.date),
              }))
            : undefined,
        tip:
          attemptResult.questionAttempt.question.missesCount > 1 ? (
            <div className={styles['tooltip']}>
              Has fallado esta pregunta{' '}
              {attemptResult.questionAttempt.question.missesCount} veces
            </div>
          ) : undefined,
      });
    }
  }, [attemptResult]);

  return (
    <>
      {attemptResult && attemptResultQuestion ? (
        <>
          <MultipleChoiceQuestionAnswer
            className={styles['questionnaire']}
            question={attemptResultQuestion}
            onBookmark={
              profile.subscription?.isActive &&
              attemptResult.questionAttempt.question.status !== 'removed'
                ? async (id) => {
                    await bookmarkMultipleChoiceQuestion({
                      subjectId: attempt?.subject.id ?? '',
                      questionId: id,
                    });
                  }
                : undefined
            }
            onUnbookmark={
              profile.subscription?.isActive
                ? async (id) => {
                    await unbookmarkMultipleChoiceQuestion({
                      subjectId: attempt?.subject.id ?? '',
                      questionId: id,
                    });
                  }
                : undefined
            }
          >
            <Countdown
              seconds={5}
              onTimeout={() => {
                onNext();
              }}
            />
          </MultipleChoiceQuestionAnswer>
        </>
      ) : attempt && attemptQuestion ? (
        <MultipleChoiceQuestion
          className={styles['questionnaire']}
          question={attemptQuestion}
          timeInSeconds={attempt.timeLimit}
          isSubmitting={isPending}
          onSubmit={(questionAnswer) => {
            const answer =
              questionAnswer.answerIndex === 0
                ? ''
                : attempt.choices[questionAnswer.answerIndex - 1].id;

            submitMultipleChoiceQuestionAttempt(
              {
                subjectId: attempt.subject.id,
                questionAttemptId: attempt.id,
                answer,
              },
              {
                onSuccess: (
                  data: SubmitQuestionAttemptCommandResult<MultipleChoiceQuestionViewModel>,
                ) => {
                  setAttemptResult(data);
                },
              },
            );
          }}
          onBookmark={
            profile.subscription?.isActive
              ? async () => {
                  await bookmarkMultipleChoiceQuestion({
                    subjectId: attempt.subject.id,
                    questionId: attempt.questionId,
                  });
                }
              : undefined
          }
          onUnbookmark={
            profile.subscription?.isActive
              ? async () => {
                  await unbookmarkMultipleChoiceQuestion({
                    subjectId: attempt.subject.id,
                    questionId: attempt.questionId,
                  });
                }
              : undefined
          }
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export { MultipleChoiceQuestionChallengeAttempt };
