import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { MultipleChoiceAssessmentAttemptCard } from './MultipleChoiceAssessmentAttemptCard';
import { MultipleChoiceQuestionAttemptCard } from './MultipleChoiceQuestionAttemptCard';

function MultipleChoiceQuestionnairesPage() {
  const { subjectId } = useParams();

  return (
    <Page title="Teoría">
      <MultipleChoiceQuestionAttemptCard subjectId={subjectId!} />
      <MultipleChoiceAssessmentAttemptCard subjectId={subjectId!} />
    </Page>
  );
}

export { MultipleChoiceQuestionnairesPage };
