import {
  Button,
  Dialog,
  DialogContent,
  FormFeedback,
  FormGroup,
  FormLabel,
  Textarea,
} from '@sealfye/ui-components';
import { Form, Formik } from 'formik';
import { IoMailOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './FeedbackForm.module.scss';

type ContainerProps = BaseComponentProps & {
  open?: boolean;
  title?: string;
  onFeedback: (value: string) => void;
  onClose: () => void;
};

function FeedbackForm({
  className,
  open,
  title,
  onFeedback,
  onClose,
  testId = 'ui-feedback-form',
}: ContainerProps) {
  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
      openAnimation="bounceInUp"
      closeAnimation="bounceOutDown"
    >
      <DialogContent className={className} data-testid={testId}>
        <div className={styles['icon']}>{<span>🐛</span>}</div>
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .max(
                2000,
                'La descripción debe contener menos de 2000 caracteres.',
              )
              .required('La descripción no puede estar vacía.'),
          })}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);

            onFeedback(values.description);
            resetForm();

            setSubmitting(false);
          }}
          initialValues={{
            description: '',
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <Form className={styles['form']} noValidate onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel>{title || '¿Has encontrado algún error?'}</FormLabel>
                <Textarea
                  id="description"
                  value={values.description}
                  rows={7}
                  onChange={handleChange}
                  error={touched.description && !!errors.description}
                />
                {touched.description && errors.description && (
                  <FormFeedback variant="danger">
                    {errors.description}
                  </FormFeedback>
                )}
                <FormFeedback>
                  Escríbenos qué error has encontrado, con esto nos ayudas a
                  mejorar cada día. Gracias compañerx! 💙
                </FormFeedback>
              </FormGroup>
              <Button
                className={styles['button']}
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
                suffixIcon={<IoMailOutline />}
              >
                Enviar feedback
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export { FeedbackForm };
