import { Ranking } from '@sealfye/ui-components';
import classnames from 'classnames';

import RankingImg from '../../../assets/images/IA31.png';
import { Loading } from '../../core/Loading';
import { MarksRankingProps } from './MarksRanking.types';

import styles from './MarksRanking.module.scss';

function MarksRanking({
  className,
  columns,
  rows,
  loading,
}: MarksRankingProps) {
  if (loading) return <Loading />;

  return (
    <div className={classnames(styles['main'], className)}>
      <img className={styles['image']} src={RankingImg} />
      <Ranking className={styles['ranking']} columns={columns} rows={rows} />
    </div>
  );
}

export { MarksRanking };
